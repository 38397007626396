<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF REMITTANCES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_remittances"
            dense
          ></v-select>
        </v-col>
        <v-col
          md="6"
          cols="12"
        >
          <v-select
            class="mx-3"
            :items="collectors_items"
            item-value="id"
            item-text="name"
            label="Collector"
            required
            @change="selected_collector"
            v-model="collector_id"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="collectors_data"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="activerow(item)"
            :class="{'info black--text': item.id===selectedDepositId}"
          >
            <td>{{ item.id }}</td>
            <td class="text-center">
              {{ item.date_of_deposit }}
            </td>
            <td class="text-center">
              {{ item.time_of_deposit }}
            </td>
            <td class="text-center">
              {{ item.amount }}
            </td>
            <td class="text-center">
              {{ item.bank_code_name}}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="remittances_dialog" width="70%">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">REMITTANCES</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-row class="pt-6 mx-4">
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.date_of_deposit"
                          label="Date Deposited"
                          dense
                          outlined
                          style="font-size: medium"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.time_of_deposit"
                          label="Time Deposited"
                          dense
                          outlined
                          style="font-size: medium"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.bank_code_name"
                          label="Bank Code"
                          dense
                          outlined
                          style="font-size: medium"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.amount"
                          label="Deposit Amount"
                          dense
                          outlined
                          style="font-size: xx-large"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.net"
                          label="Net Collection"
                          dense
                          outlined
                          style="font-size: xx-large"
            ></v-text-field>
          </v-col>
          <v-col md="4" col="12">
            <v-text-field readonly
                          v-model="data.balance"
                          label="Balance"
                          dense
                          outlined
                          style="font-size: xx-large"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">LIST OF REMITTANCES</h4>
          </v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <span class="headline font-weight-light white--text"
                v-if="collectors_remittances_data.length>0">
                                                Print ARS
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_selected_deposit_slip_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <span
            class="headline font-weight-light white--text"
            v-if="collectors_data.map(function(x) {return x.id; }).indexOf(data.id) === (collectors_data.length-1)
&& month_of_items.map(function(x) {return x.id; }).indexOf(month_of) ===0 && collectors_remittances_data.length===0">
            Delete Deposit
            <v-icon
              class="mr-2"
              color="error"
              @click="delete_remittance(collectors_data.map(function(x) {return x.id; }).indexOf(data.id))"
            >
              {{icons.mdiDelete}}
            </v-icon>
          </span>
        </v-toolbar>
        <v-data-table dense
                      :headers="headers_remittance"
                      :items="collectors_remittances_data">
          <template v-slot:item="{ item }">
            <tr
            >
              <td>
                {{ item.member_name }}
              </td>
              <td>
                {{ item.amount }}
              </td>
              <td>
                {{ item.remarks }}
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiPrinter, mdiDelete, mdiEyeOutline} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import moment from 'moment'

  export default {
    components: {
      snackBarDialog,
    },
    mounted() {
      this.retrieve_transaction_month()
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    setup() {
      return {
        icons: {
          mdiPrinter,
          mdiDelete,
          mdiEyeOutline,
        },
      }
    },
    data() {
      return {
        headers: [
          {text: 'ID', value: 'id', sortable: false},
          {text: 'DATE', value: 'date_of_deposit', sortable: false},
          {text: 'TIME', value: 'time_of_deposit', sortable: false},
          {text: 'AMOUNT', value: 'amount', sortable: false},
          {text: 'BANK', value: 'bank_code_name', sortable: false},],
        headers_remittance: [
          {text: 'NAME', value: 'time_of_deposit', sortable: false},
          {text: 'AMOUNT', value: 'amount', sortable: false},
          {text: 'REMARKS', value: 'bank_code_name', sortable: false},],
        remittances_dialog: false,

        data: [],
        collectors_data: [],
        collectors_remittances_data: [],

        month_of_items: [],
        month_of: '',
        collectors_items: [],
        collector_id: 0,
        selectedDepositId: -1,
      }
    },
    computed: {
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('deposit_slips', ['remittances_data', 'delete_remittances']),
      monthly_remittances() {
        var index = this.month_of_items.map(function (x) {
          return x.id;
        }).indexOf(this.month_of)
        if (index != -1) {
          const data = new FormData()
          data.append('month_of', this.month_of_items[index].month_of);
          this.remittances_data(data)
            .then(response => {
              this.collector_id = 0
              this.selectedDepositId = -1
              this.collectors_data = []
              this.collectors_remittances_data = []
              this.collectors_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      selected_collector() {
        var index = this.collectors_items.map(function (x) {
          return x.id;
        }).indexOf(this.collector_id)
        if (index != -1) {
          this.collectors_data = this.collectors_items[index].remittances
        }
      },
      activerow(value) {
        this.data = value
        this.remittances_dialog = true
        this.selectedDepositId = value.id;
        this.collectors_remittances_data = value.data
      },
      delete_remittance(index) {
        this.remittances_dialog = false
        const data = new FormData()
        data.append('deposit_id', this.collectors_data[index].id);
        this.delete_remittances(data)
          .then(response => {
            this.collectors_data.splice(index, 1)
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      print_selected_deposit_slip_data() {
        var imgData = this.company_logo
        var payments_array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)
        var indexCollector = this.collectors_items.map(function (x) {
          return x.id;
        }).indexOf(this.collector_id)

        var month = this.month_of_items[indexMonth].month_of
        var collector = this.collectors_items[indexCollector].name
        var dep_id = this.data.id
        var dep_amount = this.data.amount
        var total_net = this.data.net
        var total_diff = this.data.balance
        var branch = this.data.branch
        payments_array.push(
          [
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Remarks', alignment: 'center', style: 'label'}
          ]
        )
        if (this.collectors_remittances_data.length > 0) {
          this.collectors_remittances_data.forEach(function (item, index, payment) {
            payments_array.push(
              [
                {text: item.member_name, alignment: 'left'},
                {text: item.amount, alignment: 'center'},
                {text: item.remarks, alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Net:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: total_net,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Dep. Amount:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    text: dep_amount,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
              payments_array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    text: 'Balance:',
                    alignment: 'right',
                    border: [false, false, false, false],
                  },
                  {
                    color: 'red',
                    text: total_diff,
                    alignment: 'left',
                    border: [false, false, false, false],
                  },
                ]
              )
            }
          })
        } else {
          payments_array.push(
            [
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE COMMUNITY CARE FOUNDATION',
                    {text: this.data.branch.address, style: 'subheader'},
                    {
                      text: 'S.E.C REG. No. WAITING CONTACT NO.: ' + this.data.branch.contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'REMITTANCE SLIP', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.data.branch.branch_code, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            {text: 'Collector: ' + collector, style: 'main_info'},
            {text: 'Dep. ID: ' + dep_id, style: 'main_info', color: 'red'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [160, 80, 120],
                body: payments_array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: 'Collector', style: 'aa_placeholder'},
                    {text: 'Cashier', style: 'cashier_placeholder'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'cs_top_margin'},
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'cs_for'},
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'cs_line'},
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Collection Supervisor', style: 'cs_placeholder'},
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },

          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Foundation System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 9,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
